import React from "react";
import Constants from "expo-constants";
import { Linking } from "react-native";
import { ScreenContainer } from "../../../layout/ScreenContainer";
import { LineTitle } from "../../../molecules/LineTitle";
import { LinkText } from "../../../atoms/LinkText";

export const PrivacyPolicyScreen = (): React.ReactElement => {
  return (
    <ScreenContainer>
      <LineTitle
        title={`${Constants.manifest?.extra?.company} Privacy Policy`}
      />
      <LinkText
        onPress={() =>
          Linking.openURL(
            "https://www.miltongraham.com.au/legal/privacy-policy/",
          )
        }
      >
        https://www.miltongraham.com.au/legal/privacy-policy/
      </LinkText>
    </ScreenContainer>
  );
};
